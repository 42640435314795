import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { COLOR } from '@core/enum/color';
import { ChartOption } from '@core/models/interfaces/dashboard';
import { DoughnutChartLegendComponent } from '@feature/dashboard/components/doughnut-chart-legend/doughnut-chart-legend.component';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';
import { HeatMapComponent } from '../heatmap/heatmap.component';
import {
  AudienceInsights,
  ReachEngagementInsights,
  WeekDataDTO
} from '@core/models/interfaces/marketing-dashboard/dashboard';
import { CustomCalendarComponent } from '@shared/components/custom-calendar/custom-calendar.component';
import moment from 'moment';
import { resizeArray } from '@shared/utils/resizeArray';
import { SkeletonModule } from 'primeng/skeleton';
import { DateFilterType } from '@core/enum/date';

@Component({
  selector: 'app-audience-and-insights',
  standalone: true,
  imports: [
    ChartModule,
    DoughnutChartLegendComponent,
    DropdownModule,
    CommonModule,
    FormsModule,
    HeatMapComponent,
    CustomCalendarComponent,
    SkeletonModule
  ],
  templateUrl: './audience-and-insights.component.html',
  styleUrl: './audience-and-insights.component.scss'
})
export class AudienceAndInsightsComponent {
  @Input() audienceAndInsightsData: AudienceInsights;
  @Input() isLoading: boolean;

  @Output() dateFilterChange = new EventEmitter<{ startDate: string; endDate: string }>();

  readonly currentDate = new Date();
  readonly DateFilterType = DateFilterType;
  readonly barChartOptions = {
    responsive: true,
    // maintainAspectRatio: false,
    // aspectRatio: 0.8,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderDash: [5, 5]
        },
        ticks: {
          color: COLOR.GRAY
        }
        // barPercentage: 6.0,
        // categoryPercentage: 0.4
      },
      y: {
        ticks: {
          callback: function (value: number) {
            return `$${value}.00`;
          },
          stepSize: 20
        },

        color: COLOR.GRAY,
        maxTicksLimit: 7
      }
    }
  };

  ageAndGenderChartData: ChartData;
  totalAgeAndGenderData: number = 0;
  topStatesChartData: ChartData;
  totalTopStatesData: number = 0;
  placementsChartData: ChartData;
  totalPlacementsData: number = 0;
  leadsByGender: ChartOption[] = [
    {
      label: 'MALE',
      data: 1,
      color: '#3B82F6'
    },
    {
      label: 'FEMALE',
      data: 0,
      color: '#EC4899'
    },
    {
      label: 'UNKNOWN',
      data: 0,
      color: '#B3B9C4'
    }
  ];

  topStateDropdown = [
    { name: 'Amount spent', code: 'Amount spent' },
    { name: 'Conversion leads', code: 'Conversion leads' },
    { name: 'Average cost: CPC & CPL', code: 'Average cost: CPC & CPL' },
    { name: 'Impressions', code: 'Impressions' }
  ];
  generalDropdown = [
    { name: 'Amount spent', code: 'Amount spent' },
    { name: 'Conversion', code: 'Conversion' },
    { name: 'Message conversations started', code: 'Message conversations started' },
    { name: 'Cost per lead', code: 'Cost per lead' },
    { name: 'Total revenue', code: 'Total revenue' },
    { name: 'Impressions', code: 'Impressions' },
    { name: 'Clicks (all)', code: 'Clicks (all)' },
    { name: 'CTR', code: 'CTR' },
    { name: 'Average CPC', code: 'Average CPC' },
    { name: 'Cost per engagement', code: 'Cost per engagement' }
  ];
  selectedDataTypeAgeAndGender: string = 'Amount spent';
  selectedDataTypeDayAndTime: string = 'Amount spent';
  selectedDataTypePlacements: string = 'Amount spent';
  selectedDataTypeTopState: string = 'Amount spent';
  datesSelected: Array<Date | undefined> = [];

  get dateFilter() {
    const startDate = moment(this.datesSelected[0]).format('YYYY-MM-DD');
    const endDate = moment(this.datesSelected[1]).format('YYYY-MM-DD');

    return {
      startDate,
      endDate
    };
  }

  heatmap: WeekDataDTO[] = [
    {
      day: 'Sun',
      value: [1, 3, 2, 4, 5, 1, 3, 4, 2, 5, 1, 2, 4, 3, 5, 1, 2, 3, 4, 5, 1, 3, 4, 2]
    },
    {
      day: 'Sat',
      value: [5, 4, 3, 2, 1, 5, 4, 3, 2, 1, 5, 3, 4, 2, 1, 5, 4, 3, 2, 1, 5, 4, 3, 2]
    },
    {
      day: 'Fri',
      value: [3, 2, 1, 5, 4, 3, 2, 1, 5, 4, 3, 1, 2, 5, 4, 3, 2, 1, 5, 4, 3, 2, 1, 5]
    },
    {
      day: 'Thu',
      value: [4, 5, 1, 3, 2, 4, 5, 1, 3, 2, 4, 2, 5, 1, 3, 4, 2, 1, 5, 3, 4, 2, 1, 5]
    },
    {
      day: 'Wed',
      value: [2, 1, 5, 3, 4, 2, 1, 5, 3, 4, 2, 3, 1, 5, 4, 3, 2, 1, 5, 4, 3, 2, 1, 5]
    },
    {
      day: 'Tue',
      value: [1, 2, 4, 3, 5, 1, 2, 4, 3, 5, 1, 4, 3, 5, 2, 1, 4, 3, 5, 2, 1, 3, 4, 5]
    },
    {
      day: 'Mon',
      value: [5, 3, 4, 2, 1, 5, 3, 4, 2, 1, 5, 2, 4, 1, 3, 5, 2, 4, 1, 3, 5, 4, 3, 2]
    }
  ];

  ngOnInit() {
    const now = new Date();
    this.datesSelected = [moment(now).startOf('month').toDate(), moment(now).endOf('month').toDate()];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['audienceAndInsightsData']?.currentValue) {
      const data =
        this.audienceAndInsightsData?.age_gender_insights.length > 0
          ? resizeArray(this.audienceAndInsightsData.age_gender_insights, 20)
          : [];
      const topStatesData =
        this.audienceAndInsightsData?.top_states_insights.length > 0
          ? resizeArray(this.audienceAndInsightsData.top_states_insights, 20)
          : [];
      const topPlacementData =
        this.audienceAndInsightsData?.placements_insights.length > 0
          ? resizeArray(this.audienceAndInsightsData.placements_insights, 20)
          : [];
      this.mapAgeAndGenderData(data);
      this.mapTopStatesData(topStatesData);
      this.mapPlacementsData(topPlacementData);
    }
  }

  datesSelectedChange() {
    this.dateFilterChange.emit(this.dateFilter);
  }

  groupElements(arr: any[]) {
    let result = [];
    for (let i = 0; i < arr.length; i += 3) {
      result.push(arr.slice(i, i + 3));
    }
    return result;
  }

  getUniqueAges(data: any[]) {
    const uniqueAges = new Set();
    data.forEach(item => uniqueAges.add(item.age));
    return Array.from(uniqueAges);
  }

  mapAgeAndGenderData(data: ReachEngagementInsights[], dataType?: string) {
    const datasets: ChartDataset[] = [
      {
        label: 'Male',
        data: [],
        backgroundColor: COLOR.BLUE,
        maxBarThickness: 16,
        borderRadius: 2
      },
      {
        label: 'Female',
        data: [],
        backgroundColor: COLOR.PINK,
        maxBarThickness: 16,
        borderRadius: 2
      },
      {
        label: 'unknown',
        data: [],
        backgroundColor: COLOR.GRAY,
        maxBarThickness: 16,
        borderRadius: 2
      }
    ];

    const labels = this.getUniqueAges(data);
    let dataConvert = this.groupElements(this.audienceAndInsightsData.age_gender_insights);

    const itemType = dataType ? dataType.toLowerCase().replace(/\s+/g, '_').replace(/[()]/g, '') : 'amount_spent';

    datasets[0].data = dataConvert.flatMap(data => {
      return data
        ?.filter(item => item.gender === 'male')
        .map(item =>
          itemType === 'conversion'
            ? item.conversions.reduce(
                (sum: number, conv: { website: number; offline: number; meta: number }) =>
                  sum + (conv.website + conv.offline + conv.meta),
                0
              )
            : itemType === 'cost_per_lead'
              ? item.cost_per_lead.reduce((sum: number, lead: { value: number }) => sum + lead.value, 0)
              : itemType === 'message_conversations_started'
                ? item.message_conversations_started.reduce(
                    (sum: number, lead: { value: number }) => sum + lead.value,
                    0
                  )
                : itemType === 'total_revenue'
                  ? item.total_revenue.reduce(
                      (
                        sum: number,
                        revenue: {
                          cpp: { value: number };
                          leadConversion: { value: number };
                          purchaseConversion: { value: number };
                        }
                      ) => sum + (revenue.cpp.value + revenue.leadConversion.value + revenue.purchaseConversion.value),
                      0
                    )
                  : item[itemType]
        );
    });

    datasets[1].data = dataConvert.flatMap(data => {
      return data
        ?.filter(item => item.gender === 'female')
        .map(item =>
          itemType === 'conversion'
            ? item.conversions.reduce(
                (sum: number, conv: { website: number; offline: number; meta: number }) =>
                  sum + (conv.website + conv.offline + conv.meta),
                0
              )
            : itemType === 'cost_per_lead'
              ? item.cost_per_lead.reduce((sum: number, lead: { value: number }) => sum + lead.value, 0)
              : itemType === 'message_conversations_started'
                ? item.message_conversations_started.reduce(
                    (sum: number, lead: { value: number }) => sum + lead.value,
                    0
                  )
                : itemType === 'total_revenue'
                  ? item.total_revenue.reduce(
                      (
                        sum: number,
                        revenue: {
                          cpp: { value: number };
                          leadConversion: { value: number };
                          purchaseConversion: { value: number };
                        }
                      ) => sum + (revenue.cpp.value + revenue.leadConversion.value + revenue.purchaseConversion.value),
                      0
                    )
                  : item[itemType]
        );
    });

    datasets[2].data = dataConvert.flatMap(data => {
      return data
        ?.filter(item => item.gender === 'unknown')
        .map(item =>
          itemType === 'conversion'
            ? item.conversions.reduce(
                (sum: number, conv: { website: number; offline: number; meta: number }) =>
                  sum + (conv.website + conv.offline + conv.meta),
                0
              )
            : itemType === 'cost_per_lead'
              ? item.cost_per_lead.reduce((sum: number, lead: { value: number }) => sum + lead.value, 0)
              : itemType === 'message_conversations_started'
                ? item.message_conversations_started.reduce(
                    (sum: number, lead: { value: number }) => sum + lead.value,
                    0
                  )
                : item[itemType]
        );
    });

    const totalMale = datasets[0].data.map(v => (typeof v === 'number' ? v : 0)).reduce((sum, value) => sum + value, 0);
    const totalFemale = datasets[1].data
      .map(v => (typeof v === 'number' ? v : 0))
      .reduce((sum, value) => sum + value, 0);
    const totalUnknown = datasets[2].data
      .map(v => (typeof v === 'number' ? v : 0))
      .reduce((sum, value) => sum + value, 0);

    this.totalAgeAndGenderData = totalMale + totalFemale + totalUnknown;

    this.ageAndGenderChartData = {
      labels,
      datasets
    };
  }

  mapTopStatesData(data: ReachEngagementInsights[], dataType?: string) {
    const itemType = dataType
      ? dataType.toLowerCase().replace(/\s+/g, '_').replace(/[()]/g, '')
      : dataType === 'Average cost: CPC & CPL'
        ? 'average_cost'
        : 'amount_spent';

    const datasetData = data.map(item =>
      itemType === 'conversion' && Array.isArray(item.conversions)
        ? item.conversions.reduce((sum, conv) => sum + (conv.website + conv.offline + conv.meta), 0)
        : itemType === 'cost_per_lead' && Array.isArray(item.cost_per_lead)
          ? item.cost_per_lead.reduce((sum, lead) => sum + lead.value, 0)
          : itemType === 'message_conversations_started' && Array.isArray(item.message_conversations_started)
            ? item.message_conversations_started.reduce((sum, lead) => sum + lead.value, 0)
            : itemType === 'conversion_leads' && Array.isArray(item.conversion_leads)
              ? item.conversion_leads.reduce((sum, lead) => sum + lead.value, 0)
              : (item[itemType as keyof ReachEngagementInsights] ?? 0)
    );

    this.totalTopStatesData = datasetData.reduce((sum, value) => sum + (typeof value === 'number' ? value : 0), 0);

    this.topStatesChartData = {
      labels: data.map(item => item.country_code),
      datasets: [
        {
          label: dataType ? dataType : 'Amount spent',
          borderColor: '#3B82F6',
          borderWidth: 2,
          fill: false,
          barThickness: 20,
          tension: 0.05,
          data: datasetData,
          pointRadius: 0,
          pointHoverRadius: 5,
          pointHitRadius: 10,
          backgroundColor: '#3B82F6',
          borderRadius: 2
        }
      ]
    };
  }

  mapPlacementsData(data: ReachEngagementInsights[], dataType?: string) {
    const itemType = dataType
      ? dataType.toLowerCase().replace(/\s+/g, '_').replace(/[()]/g, '')
      : dataType === 'Average CPC'
        ? 'average_cpc'
        : 'amount_spent';

    const datasetData = data.map(item =>
      itemType === 'conversion' && Array.isArray(item.conversions)
        ? item.conversions.reduce((sum, conv) => sum + (conv.website + conv.offline + conv.meta), 0)
        : itemType === 'cost_per_lead' && Array.isArray(item.cost_per_lead)
          ? item.cost_per_lead.reduce((sum, lead) => sum + lead.value, 0)
          : itemType === 'message_conversations_started' && Array.isArray(item.message_conversations_started)
            ? item.message_conversations_started.reduce((sum, lead) => sum + lead.value, 0)
            : itemType === 'conversion_leads' && Array.isArray(item.conversion_leads)
              ? item.conversion_leads.reduce((sum, lead) => sum + lead.value, 0)
              : (item[itemType as keyof ReachEngagementInsights] ?? 0)
    );

    const totalChartData = datasetData.reduce((sum, value) => sum + (typeof value === 'number' ? value : 0), 0);

    this.placementsChartData = {
      labels: data.map(item => item.platform_position?.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())),
      datasets: [
        {
          label: dataType ? dataType : 'Amount spent',
          borderColor: '#3B82F6',
          borderWidth: 2,
          fill: false,
          tension: 0.05,
          data: datasetData,
          pointRadius: 0,
          pointHoverRadius: 5,
          pointHitRadius: 10,
          backgroundColor: '#3B82F6',
          borderRadius: 2
        }
      ]
    };

    this.totalPlacementsData = totalChartData;
  }

  mapDayAndTimeData(data: ReachEngagementInsights[], dataType?: string) {
    const itemType = dataType
      ? dataType.toLowerCase().replace(/\s+/g, '_').replace(/[()]/g, '')
      : dataType === 'Average CPC'
        ? (dataType = 'average_cpc')
        : 'amount_spent';

    this.placementsChartData = {
      labels: data.map(item => item.platform_position?.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())),
      datasets: [
        {
          label: 'Cost per lead',
          borderColor: '#3B82F6',
          borderWidth: 2,
          fill: false,
          tension: 0.05,
          data: data.map(item =>
            itemType === 'conversion' && Array.isArray(item.conversions)
              ? item.conversions.reduce(
                  (sum: number, conv: { website: number; offline: number; meta: number }) =>
                    sum + (conv.website + conv.offline + conv.meta),
                  0
                )
              : itemType === 'cost_per_lead' && Array.isArray(item.cost_per_lead)
                ? item.cost_per_lead.reduce((sum: number, lead: { value: number }) => sum + lead.value, 0)
                : itemType === 'message_conversations_started' && Array.isArray(item.message_conversations_started)
                  ? item.message_conversations_started.reduce(
                      (sum: number, lead: { value: number }) => sum + lead.value,
                      0
                    )
                  : itemType === 'conversion_leads' && Array.isArray(item.conversion_leads)
                    ? item.conversion_leads.reduce((sum: number, lead: { value: number }) => sum + lead.value, 0)
                    : item[itemType as keyof ReachEngagementInsights]
          ),
          pointRadius: 0,
          pointHoverRadius: 5,
          pointHitRadius: 10,
          backgroundColor: '#3B82F6',
          borderRadius: 2
        }
      ]
    };
  }

  onChangeDataTypeTopState() {
    this.mapTopStatesData(this.audienceAndInsightsData.top_states_insights, this.selectedDataTypeTopState);
  }

  onChangeDataTypeAgeGender() {
    this.mapAgeAndGenderData(this.audienceAndInsightsData.age_gender_insights, this.selectedDataTypeAgeAndGender);
  }

  onChangeDataTypeDay() {}

  onChangeDataTypePlacements() {
    this.mapPlacementsData(this.audienceAndInsightsData.placements_insights, this.selectedDataTypePlacements);
  }
}
