import { Routes } from '@angular/router';
import { ngxPermissionsGuard } from 'ngx-permissions';

import { APP_PERMISSION } from '@core/enum/permisson';
import { authGuard } from '@core/guards/auth.guard';
import { CustomPermissionsGuard } from '@core/guards/custom-permission.guard';
import { AuthLayoutComponent } from '@core/layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from '@core/layouts/main-layout/main-layout.component';
import { AccountSettingComponent } from '@feature/account-setting/account-setting.component';
import { AdminContentComponent } from '@feature/admin-content/admin-content.component';
import { CompanyOrganizationComponent } from '@feature/company-organization/company-organization.component';
import { DashboardComponent } from '@feature/dashboard/dashboard.component';
import { DataConfigurationComponent } from '@feature/data-configuration/data-configuration.component';
import { NoteManagementComponent } from '@feature/note/pages/note-management/note-management.component';
import { NotificationsComponent } from '@feature/notifications/notifications.component';
import { ReportComponent } from '@feature/report/report.component';
import { PreviewAttachmentComponent } from '@shared/components/preview-attachment/preview-attachment.component';
import { NotFoundComponent } from '@shared/pages/not-found/not-found.component';
import { WaitingCompleteComponent } from '@shared/components/waiting-complete/waiting-complete.component';
import { IntegrationComponent } from '@feature/marketing-setting/pages/integration/integration.component';
import { PartnerAppsComponent } from '@feature/partner-apps/partner-apps.component';
import { MarketingDashboardComponent } from '@feature/marketing-dashboard/marketing-dashboard.component';

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./features/home-page/home-page.routes').then(feature => feature.routes)
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: [
              APP_PERMISSION.DASHBOARD_LEAD_VIEW,
              APP_PERMISSION.DASHBOARD_PERFORMANCE_VIEW,
              APP_PERMISSION.DASHBOARD_POLICY_VIEW,
              APP_PERMISSION.DASHBOARD_USER_VIEW
            ],
            redirectTo: '/home'
          }
        }
      },
      {
        path: 'account-setting',
        component: AccountSettingComponent
      },
      {
        path: 'lead-management',
        loadChildren: () => import('./features/lead-management/lead-management.routes').then(feature => feature.routes)
      },
      {
        path: 'permissions',
        loadChildren: () => import('./features/permissions/permissions.routes').then(feature => feature.routes),
        canActivate: [CustomPermissionsGuard],
        data: {
          permissions: {
            only: [APP_PERMISSION.SETTINGS_VIEW, APP_PERMISSION.PERMISSIONS_VIEW],
            redirectTo: '/home'
          }
        }
      },
      {
        path: 'user-groups',
        loadChildren: () => import('./features/lead-view/lead-view.routes').then(feature => feature.routes),
        canActivate: [CustomPermissionsGuard],
        data: {
          permissions: {
            only: [APP_PERMISSION.SETTINGS_VIEW, APP_PERMISSION.USER_GROUPS_VIEW],
            redirectTo: '/home'
          }
        }
      },
      {
        path: 'tasks',
        loadChildren: () => import('./features/task-management/task-management.routes').then(feature => feature.routes),
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: [APP_PERMISSION.TASK_MANAGEMENT_VIEW, APP_PERMISSION.APPOINTMENT_VIEW],
            redirectTo: '/home'
          }
        }
      },

      {
        path: 'policy',
        loadChildren: () => import('./features/policy/policy-management.routes').then(feature => feature.routes),
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: APP_PERMISSION.POLICY_MANAGEMENT_VIEW,
            redirectTo: '/home'
          }
        }
      },

      {
        path: 'notes',
        component: NoteManagementComponent,
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: APP_PERMISSION.NOTE_MANAGEMENT_VIEW,
            redirectTo: '/home'
          }
        }
      },

      {
        path: 'report',
        component: ReportComponent,
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: [
              APP_PERMISSION.REPORT_PRODUCTION_DETAILS_RUN,
              APP_PERMISSION.REPORT_PERFORMANCE_RUN,
              APP_PERMISSION.REPORT_LEAD_RUN
            ],
            redirectTo: '/home'
          }
        }
      },

      {
        path: 'data-config',
        component: DataConfigurationComponent,
        canActivate: [CustomPermissionsGuard],
        data: {
          permissions: {
            only: [APP_PERMISSION.DATA_CONFIGURATION, APP_PERMISSION.SETTINGS_VIEW],
            redirectTo: '/home'
          }
        }
      },

      {
        path: 'permissions',
        loadChildren: () => import('./features/permissions/permissions.routes').then(feature => feature.routes),
        canActivate: [CustomPermissionsGuard],
        data: {
          permissions: {
            only: [APP_PERMISSION.PERMISSIONS_VIEW, APP_PERMISSION.SETTINGS_VIEW],
            redirectTo: '/home'
          }
        }
      },

      {
        path: 'lead-viewers',
        loadChildren: () => import('./features/lead-view/lead-view.routes').then(feature => feature.routes)
      },

      {
        path: 'company-organization',
        component: CompanyOrganizationComponent,
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: [APP_PERMISSION.SETTINGS_VIEW, APP_PERMISSION.ORGANIZATION_VIEW],
            redirectTo: '/home'
          }
        }
      },

      {
        path: 'users',
        loadChildren: () => import('./features/user-management/user-management.routes').then(feature => feature.routes),
        canActivate: [CustomPermissionsGuard],
        data: {
          permissions: {
            only: [APP_PERMISSION.LIST_USER_VIEW, APP_PERMISSION.SETTINGS_VIEW],
            redirectTo: '/home'
          }
        }
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
        canActivate: [CustomPermissionsGuard],
        data: {
          permissions: {
            only: [APP_PERMISSION.NOTIFICATION_VIEW],
            redirectTo: '/home'
          }
        }
      },

      {
        path: 'admin-content',
        component: AdminContentComponent,
        canActivate: [CustomPermissionsGuard],
        data: {
          permissions: {
            only: [
              APP_PERMISSION.SETTINGS_VIEW || [
                APP_PERMISSION.POST_VIEW,
                APP_PERMISSION.LINK_VIEW,
                APP_PERMISSION.BANNER_VIEW
              ]
            ],
            redirectTo: '/home'
          }
        }
      },
      {
        path: 'partner-apps',
        component: PartnerAppsComponent,
        canActivate: [CustomPermissionsGuard]
      },

      {
        path: 'marketing-dashboard',
        component: MarketingDashboardComponent
      },

      {
        path: 'marketing-campaigns',
        loadChildren: () =>
          import('./features/marketing-campaign/marketing-campaign.routes').then(feature => feature.routes)
      },

      {
        path: 'marketing-audiences',
        loadChildren: () =>
          import('./features/marketing-audience/marketing-audience.routes').then(feature => feature.routes)
      },

      {
        path: 'marketing-payments',
        loadChildren: () =>
          import('./features/marketing-payment/marketing-payment.routes').then(feature => feature.routes)
      },

      {
        path: 'data-sources',
        loadChildren: () => import('./features/data-sources/data-sources.routes').then(feature => feature.routes)
      },

      {
        path: 'marketing-integration',
        component: IntegrationComponent
      },

      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'prefix'
      }
    ]
  },
  {
    path: 'file',
    component: PreviewAttachmentComponent,
    canActivate: [authGuard, ngxPermissionsGuard],
    data: {
      permissions: {
        only: [APP_PERMISSION.ATTACHMENT_OPEN, APP_PERMISSION.CONFIDENTIAL_ATTACHMENT_OPEN],
        redirectTo: '/home'
      }
    }
  },
  {
    path: '',
    component: AuthLayoutComponent,
    loadChildren: () => import('./features/auth/auth.routes').then(feature => feature.routes)
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent
  }
];
