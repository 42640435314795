import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DashboardStatisticComponent } from '@shared/components/dashboard-statistic/dashboard-statistic.component';
import { UNIT_NUMBER } from '@core/enum/dashboard';
import { ChartModule } from 'primeng/chart';
import { DoughnutChartLegendComponent } from '@feature/dashboard/components/doughnut-chart-legend/doughnut-chart-legend.component';
import { ChartOption } from '@core/models/interfaces/dashboard';
import { BarLineChartComponent } from '../bar-line-chart/bar-line-chart.component';
import { ChartData, TooltipItem } from 'chart.js';
import { CommonModule } from '@angular/common';
import { PerformanceInsights } from '@core/models/interfaces/marketing-dashboard/dashboard';
import { resizeArray } from '@shared/utils/resizeArray';
import moment from 'moment';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-performance-overview',
  standalone: true,
  imports: [
    DashboardStatisticComponent,
    ChartModule,
    DoughnutChartLegendComponent,
    BarLineChartComponent,
    CommonModule,
    SkeletonModule
  ],
  templateUrl: './performance-overview.component.html',
  styleUrl: './performance-overview.component.scss'
})
export class PerformanceOverviewComponent implements OnChanges {
  @Input() isLoading: boolean = false;
  @Input() performanceData: PerformanceInsights = {
    spendInsights: [],
    revenue: {
      message: 0,
      website: 0,
      leadForm: 0
    },
    leads: [],
    messageStarted: [],
    conversionStatistical: {
      conversionLeads: {
        amount: 0,
        rate: 0
      },
      total: {
        amount: 0,
        rate: 0
      },
      cpc: {
        amount: 0,
        rate: 0
      }
    },
    messageStartedStatistical: {
      conversionMessage: {
        amount: 0,
        rate: 0
      },
      total: {
        amount: 0,
        rate: 0
      },
      cpc: {
        amount: 0,
        rate: 0
      }
    }
  };

  readonly UNIT_NUMBER = UNIT_NUMBER;

  performanceConversionLeads = [
    {
      id: 'Conversion_leads',
      title: 'Conversion leads',
      amount: 0,
      dataType: UNIT_NUMBER.NUMBER,
      isAll: false,
      rate: 0
    },
    {
      id: 'Total_cost',
      title: 'Total cost',
      amount: 0,
      dataType: UNIT_NUMBER.CURRENCY,
      isAll: false,
      rate: 0
    },
    {
      id: 'Cost_per_conversion',
      title: 'Cost per conversion',
      amount: 0,
      dataType: UNIT_NUMBER.CURRENCY,
      isAll: false,
      rate: 0
    }
  ];

  performanceConversionMessages = [
    {
      id: 'Conversion_messages',
      title: 'Conversion messages',
      amount: 0,
      dataType: UNIT_NUMBER.NUMBER,
      isAll: false,
      rate: 0
    },
    {
      id: 'Total_cost',
      title: 'Total cost',
      amount: 0,
      dataType: UNIT_NUMBER.CURRENCY,
      isAll: false,
      rate: 0
    },
    {
      id: 'Cost_per_conversion',
      title: 'Cost per conversion',
      amount: 0,
      dataType: UNIT_NUMBER.CURRENCY,
      isAll: false,
      rate: 0
    }
  ];

  leadsOptions: ChartOption[] = [
    {
      label: 'Conversion leads',
      data: 5,
      color: '#3B82F6'
    },
    {
      label: 'Cost per lead',
      data: 5,
      color: '#F97316'
    }
  ];

  dataLeads: ChartData;
  dataConversion: ChartData;
  //Amount spent
  dataAmountSpent: ChartData;
  totalAmountSpent: number = 0;
  //Revenue
  conversionRevenue: ChartData;
  totalRevenue: number = 0;
  //Message
  conversionOption: ChartOption[] = [
    {
      label: 'Message conversation started',
      data: 5,
      color: '#8B5CF6'
    },
    {
      label: 'Cost per message',
      data: 5,
      color: '#F97316'
    }
  ];

  doughnutOption: ChartOption[] = [
    {
      label: 'Message Conversion Value',
      data: 5,
      color: '#BBF7D0'
    },
    {
      label: 'Lead Form Conversion Value',
      data: 5,
      color: '#16A34A'
    },
    {
      label: 'Website Conversion Value',
      data: 5,
      color: '#4ADE80'
    }
  ];

  optionsLead = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 14 // Set font size
          }
        },
        grid: {
          display: false
        }
      },
      y: {
        ticks: {
          callback: function (value: number) {
            return `$${value}.00`;
          },
          stepSize: 2,
          font: {
            size: 14 // Set font size
          }
        },
        grid: {
          display: true, // Enable/disable grid lines
          lineWidth: 0.3, // Set the width of the grid lines
          drawTicks: false
        },
        border: {
          display: false
        }
      }
    }
  };

  optionsConversion = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 14 // Set font size
          }
        },
        grid: {
          display: false
        }
      },
      y: {
        ticks: {
          callback: function (value: number) {
            return `$${value}.00`;
          },
          stepSize: 2,
          font: {
            size: 14 // Set font size
          }
        },
        grid: {
          lineWidth: 0.3, // Set the width of the grid lines

          drawTicks: false
        },
        border: {
          display: false
        }
      }
    }
  };

  doughnutChartOptions = {
    cutout: '70%',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: TooltipItem<'doughnut'>) {
            const total = tooltipItem?.dataset?.data?.reduce((a: number, b: number) => a + b, 0) || 0;
            return `${tooltipItem?.formattedValue} (${(((tooltipItem?.parsed || 0) / total) * 100).toFixed(2)}%)`;
          }
        }
      }
    }
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['performanceData']?.currentValue) {
      this.mapStatisticData();
    }
  }

  mapStatisticData() {
    this.mapStatisticDataLeads();
    this.mapStatisticDataMessage();
    this.mapDataRevenue();
    this.mapSpendInsights();
    this.mapConversionLeads();
    this.mapConversionMessage();
  }

  mapStatisticDataLeads() {
    const { conversionLeads, total, cpc } = this.performanceData?.conversionStatistical;
    this.performanceConversionLeads.forEach(statistic => {
      switch (statistic.id) {
        case 'Conversion_leads': {
          statistic.amount = conversionLeads.amount;
          statistic.rate = conversionLeads.rate;
          break;
        }

        case 'Total_cost': {
          statistic.amount = total.amount;
          statistic.rate = total.rate;
          break;
        }

        case 'Cost_per_conversion': {
          statistic.amount = cpc.amount;
          statistic.rate = cpc.rate;
          break;
        }
      }
    });
  }

  mapStatisticDataMessage() {
    const { conversionMessage, total, cpc } = this.performanceData?.messageStartedStatistical;
    this.performanceConversionMessages.forEach(statistic => {
      switch (statistic.id) {
        case 'Conversion_messages': {
          statistic.amount = conversionMessage.amount;
          statistic.rate = conversionMessage.rate;
          break;
        }

        case 'Total_cost': {
          statistic.amount = total.amount;
          statistic.rate = total.rate;
          break;
        }

        case 'Cost_per_conversion': {
          statistic.amount = cpc.amount;
          statistic.rate = cpc.rate;
          break;
        }
      }
    });
  }

  mapDataRevenue() {
    if (this.performanceData?.revenue) {
      const { leadForm, message, website } = this.performanceData?.revenue;
      this.totalRevenue = leadForm + message + website;
      this.conversionRevenue = {
        labels: ['Message Conversion Value', 'Lead Form Conversion Value', 'Website Conversion Value'],
        datasets: [
          {
            data: [message, leadForm, website],
            backgroundColor: ['#BBF7D0', '#16A34A', '#4ADE80'],
            hoverBackgroundColor: ['#BBF7D0', '#16A34A', '#4ADE80']
          }
        ]
      };
    }
  }

  mapSpendInsights() {
    const { spendInsights } = this.performanceData;
    if (spendInsights.length) {
      this.totalAmountSpent = spendInsights.reduce((prev, curr) => {
        return prev + (curr?.spend || 0);
      }, 0);
      const data = resizeArray(spendInsights, 10);

      this.dataAmountSpent = {
        labels: data.map(item => moment(item.dateStart, 'YYYY-MM-DD').format('MMM DD')),
        datasets: [
          {
            label: 'Cost per lead',
            borderColor: '#3B82F6',
            borderWidth: 2,
            fill: false,
            tension: 0.05,
            data: data.map(item => item.spend || 0),
            pointRadius: 0,
            pointHoverRadius: 5,
            pointHitRadius: 10,
            backgroundColor: '#3B82F6',
            borderRadius: 2
          }
        ]
      };
    }
  }

  mapConversionLeads() {
    const { leads } = this.performanceData;
    if (leads.length) {
      const data = resizeArray(leads, 30);
      this.dataLeads = {
        labels: data.map(item => moment(item.dateStart, 'YYYY-MM-DD').format('MMM DD')),
        datasets: [
          {
            type: 'line',
            label: 'Cost per lead',
            borderColor: '#F97316',
            borderWidth: 2,
            fill: false,
            tension: 0.2,
            data: data.map(item => item?.cpp?.value || 0),
            pointRadius: 0,
            pointHoverRadius: 5,
            pointHitRadius: 10
          },
          {
            type: 'bar',
            label: 'Conversion leads',
            backgroundColor: '#3B82F6',
            data: data.map(item => item?.leadConversion?.value || 0),
            borderColor: '#3B82F6',
            borderWidth: 2,
            borderRadius: 2,
            barThickness: 20
          }
        ]
      };
    }
  }

  mapConversionMessage() {
    const { messageStarted } = this.performanceData;
    if (messageStarted.length) {
      const data = resizeArray(messageStarted, 30);

      this.dataConversion = {
        labels: data.map(item => moment(item.dateStart, 'YYYY-MM-DD').format('MMM DD')),
        datasets: [
          {
            type: 'line',
            label: 'Cost per lead',
            borderColor: '#F97316',
            borderWidth: 2,
            fill: false,
            tension: 0.2,
            data: data.map(item => item?.cpm?.value || 0),
            pointRadius: 0,
            pointHoverRadius: 5,
            pointHitRadius: 10
          },
          {
            type: 'bar',
            label: 'Conversion leads',
            backgroundColor: '#8B5CF6',
            data: data.map(item => item?.messageStarted?.value || 0),
            borderColor: '#8B5CF6',
            borderWidth: 2,
            borderRadius: 2,
            barThickness: 20
          }
        ]
      };
    }
  }
}
