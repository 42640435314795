import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateFilterType } from '@core/enum/date';
import { AdAccount, AdAccountWithBusiness } from '@core/models/interfaces/facebook/integration';
import {
  PerformanceInsights,
  AudienceInsights,
  ReachEngagementInsights,
  ReachEngagementStatistical
} from '@core/models/interfaces/marketing-dashboard/dashboard';
import { FacebookAdsAdminService } from '@core/services/facebook.service.ts/facebook-ads-admin.service';
import { FacebookDashboardService } from '@core/services/facebook.service.ts/facebook-dashboard.service';
import { Store } from '@ngrx/store';
import { AdAccountSelectComponent } from '@shared/components/ad-account-select/ad-account-select.component';
import { CustomCalendarComponent } from '@shared/components/custom-calendar/custom-calendar.component';
import { AppState } from '@state/app.state';
import { selectFacebookAccountSelected } from '@state/auth/auth.selectors';
import moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { AudienceAndInsightsComponent } from './components/audience-and-insights/audience-and-insights.component';
import { ExportReportDashboardComponent } from './components/export-report-dashboard/export-report-dashboard.component';
import { PerformanceOverviewComponent } from './components/performance-overview/performance-overview.component';
import { ReachEngagementPerformanceComponent } from './components/reach-engagement-performance/reach-engagement-performance.component';
import { FacebookAccount } from '@core/models/interfaces/facebook/facebook-account';

@Component({
  selector: 'app-marketing-dashboard',
  standalone: true,
  imports: [
    AudienceAndInsightsComponent,
    PerformanceOverviewComponent,
    ReachEngagementPerformanceComponent,
    AdAccountSelectComponent,
    CustomCalendarComponent,
    ExportReportDashboardComponent
  ],
  templateUrl: './marketing-dashboard.component.html',
  styleUrl: './marketing-dashboard.component.scss'
})
export class MarketingDashboardComponent implements OnInit, OnDestroy {
  readonly unsubscribe$ = new Subject();
  readonly DateFilterType = DateFilterType;
  readonly currentDate = new Date();

  initial: boolean = false;
  facebookAccount: FacebookAccount;
  accountBusinesses: AdAccountWithBusiness[] = [];
  datesSelected: Date[] = [];
  adAccountSelected: AdAccount = {
    id: '',
    name: ''
  };

  //data
  reachEngagementLoading: boolean = false;
  audienceAndInsightsLoading: boolean = false;
  reachEngagementData: ReachEngagementInsights[] = [];
  audienceAndInsightsData: AudienceInsights;
  reachEngagementStatistic: ReachEngagementStatistical;
  performanceData: PerformanceInsights;
  performanceLoading: boolean = false;
  now = new Date();
  dateFilterAudienceAndInsights: { startDate: string; endDate: string } = {
    startDate: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
    endDate: moment(new Date()).endOf('month').format('YYYY-MM-DD')
  };

  get dateFilter() {
    const startDate = moment(this.datesSelected[0]).format('YYYY-MM-DD');
    const endDate = moment(this.datesSelected[1]).format('YYYY-MM-DD');

    return {
      startDate,
      endDate
    };
  }

  constructor(
    private facebookAdsAdminService: FacebookAdsAdminService,
    private facebookDashboardService: FacebookDashboardService,
    private messageService: MessageService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    const now = new Date();
    this.datesSelected = [moment(now).startOf('month').toDate(), moment(now).endOf('month').toDate()];

    this.getAccessToken();
    this.initData();
  }

  getAccessToken() {
    this.store
      .select(selectFacebookAccountSelected)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(acc => {
        if (acc) {
          this.facebookAccount = acc;
        }
      });
  }

  initData() {
    if (!this.facebookAccount) return;
    const { accessToken, id } = this.facebookAccount;
    this.facebookAdsAdminService
      .getAdAccountsWithBusiness(accessToken, id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.accountBusinesses = res.data;
          if (this.accountBusinesses.length) {
            const adAccountStores = sessionStorage.getItem('adAccountInit');

            if (adAccountStores) {
              const adAccountSelected = JSON.parse(adAccountStores);
              const adAccounts = this.accountBusinesses.flatMap(item => item.act_account);
              if (adAccounts.find(item => item.id === adAccountSelected?.id)) {
                this.adAccountSelected = JSON.parse(adAccountStores);
              } else {
                this.adAccountSelected = this.accountBusinesses?.[0].act_account?.[0];
              }
            } else {
              this.adAccountSelected = this.accountBusinesses?.[0].act_account?.[0];
            }
          }
          this.fetchData();
          this.fetchAudienceAndInsights();
          this.initial = true;
        },
        error: () => {
          this.initial = true;
        }
      });
  }

  onChangeAdAccount() {
    this.fetchData();
    this.fetchAudienceAndInsights();
  }

  datesSelectedChange() {
    this.fetchData();
  }

  fetchData() {
    this.fetchReachAndEngagementStatistic();
    this.fetchReachAndEngagementInsights();
    this.fetchPerformanceInsights();
  }

  onDateFilterChange(event: any) {
    this.dateFilterAudienceAndInsights = event;
    this.fetchAudienceAndInsights();
  }

  fetchAudienceAndInsights() {
    const { startDate, endDate } = this.dateFilterAudienceAndInsights;
    this.audienceAndInsightsLoading = true;
    this.facebookDashboardService
      .getAudienceInsights(this.adAccountSelected.id, this.facebookAccount.accessToken, startDate, endDate)
      .subscribe({
        next: res => {
          if (res.data) {
            this.audienceAndInsightsData = res.data;
          }
          this.audienceAndInsightsLoading = false;
        },
        error: err => {
          this.messageService.add({
            severity: 'error',
            detail: err?.error?.error
          });
          this.audienceAndInsightsLoading = false;
        }
      });
  }

  fetchReachAndEngagementStatistic() {
    const { startDate, endDate } = this.dateFilter;
    this.facebookDashboardService
      .getReachEngagementStatistical(this.adAccountSelected.id, this.facebookAccount.accessToken, startDate, endDate)
      .subscribe({
        next: res => {
          if (res.data) {
            this.reachEngagementStatistic = res.data;
          }
        },
        error: err => {
          this.messageService.add({
            severity: 'error',
            detail: err?.error?.error
          });
        }
      });
  }

  fetchReachAndEngagementInsights() {
    const { startDate, endDate } = this.dateFilter;
    this.reachEngagementLoading = true;
    this.facebookDashboardService
      .getReachEngagementInsights(this.adAccountSelected.id, this.facebookAccount.accessToken, startDate, endDate)
      .subscribe({
        next: res => {
          if (res.data) {
            this.reachEngagementData = res.data;
          }
          this.reachEngagementLoading = false;
        },
        error: err => {
          this.messageService.add({
            severity: 'error',
            detail: err?.error?.error
          });

          this.reachEngagementLoading = false;
        }
      });
  }

  fetchPerformanceInsights() {
    const { startDate, endDate } = this.dateFilter;
    this.performanceLoading = true;
    this.facebookDashboardService
      .getPerformanceInsights(this.adAccountSelected.id, this.facebookAccount.accessToken, startDate, endDate)
      .subscribe({
        next: res => {
          if (res.data) {
            this.performanceData = res.data;
          }
          this.performanceLoading = false;
        },
        error: err => {
          this.messageService.add({
            severity: 'error',
            detail: err?.error?.error
          });

          this.performanceLoading = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
