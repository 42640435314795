<div class="flex flex-col gap-5 border rounded-md py-5">
  <div class="flex items-center justify-between px-6">
    <div class="flex flex-col gap-1">
      <span class="text-4 font-semibold">{{ title }}</span>
      @if (loading) {
        <p-skeleton width="4rem" height="32px" />
      } @else {
        <span class="text-7 font-semibold">
          @if (dataType === UNIT_NUMBER.NUMBER) {
            {{ totalAmount | number }}
          } @else if (dataType === UNIT_NUMBER.PERCENT) {
            {{ totalAmount.toFixed(2) }}%
          } @else if (dataType === UNIT_NUMBER.CURRENCY) {
            {{ totalAmount | currency }}
          }
        </span>
      }
    </div>

    <p-selectButton
      styleClass="my-4"
      [options]="tabOptions"
      [allowEmpty]="false"
      [(ngModel)]="tabActive"
      optionValue="value">
      <ng-template pTemplate="item" let-item>
        <div>
          {{ item.title }}
        </div>
      </ng-template>
    </p-selectButton>
  </div>
  <div class="px-6">
    @if (loading) {
      <p-skeleton width="100%" height="280px" />
    } @else {
      @if (tabActive === 'BAR') {
        <p-chart type="bar" [data]="data" height="280px" [options]="chartOptions"></p-chart>
      } @else {
        <p-chart type="line" [data]="data" height="280px" [options]="chartOptions"></p-chart>
      }
    }
  </div>
</div>
