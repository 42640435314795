<div class="flex flex-row ml-auto justify-between items-center pt-6 pb-4">
  <span class="text-neutral-600 text-4 font-normal">{{ 'AUDIENCE AND INSIGHTS' }}</span>
  <app-custom-calendar
    [maxDate]="currentDate"
    [filterTypeInit]="DateFilterType.THIS_MONTH"
    [(datesSelected)]="datesSelected"
    (datesSelectedChange)="datesSelectedChange()"
    appendTo="body"></app-custom-calendar>
</div>
@if (isLoading) {
  <p-skeleton width="100%" height="280px" />
} @else {
  <div class="grid grid-cols-2 gap-6">
    <div class="flex flex-col justify-between rounded-md border px-6 py-5 col-span-1">
      <div>
        <div class="flex flex-row justify-between items-center">
          <h2 class="font-semibold text-4">{{ 'Age and Gender' }}</h2>
          <p-dropdown
            styleClass="h-9 w-full flex ml-auto"
            [options]="generalDropdown"
            appendTo="body"
            optionLabel="name"
            optionValue="code"
            dropdownIcon="icon-wl-arrow-drop-down"
            [(ngModel)]="selectedDataTypeAgeAndGender"
            (onChange)="onChangeDataTypeAgeGender()"></p-dropdown>
        </div>
        <span class="font-semibold text-7">{{ totalAgeAndGenderData | currency: 'USD' }}</span>

        <div class="flex-1 pt-5 pb-4">
          <p-chart
            height="256px"
            width="100%"
            type="bar"
            [data]="ageAndGenderChartData"
            [options]="barChartOptions"></p-chart>
        </div>
      </div>

      <app-doughnut-chart-legend [options]="leadsByGender"></app-doughnut-chart-legend>
    </div>

    <div class="flex flex-col justify-between rounded-md border px-6 py-5 col-span-1">
      <div>
        <div class="flex flex-row justify-between items-center">
          <h2 class="font-semibold text-4">{{ 'Day and time' }}</h2>
          <p-dropdown
            styleClass="h-9 w-full flex ml-auto"
            [options]="generalDropdown"
            appendTo="body"
            optionLabel="name"
            optionValue="code"
            dropdownIcon="icon-wl-arrow-drop-down"
            [(ngModel)]="selectedDataTypeDayAndTime"
            (onChange)="onChangeDataTypeDay()"></p-dropdown>
        </div>
        <span class="font-semibold text-7 pb-5">{{ '$224.32' }}</span>
        <app-heatmap [data]="heatmap"></app-heatmap>
      </div>
    </div>

    <div class="flex flex-col justify-between rounded-md border px-6 py-5 col-span-1">
      <div>
        <div class="flex flex-row justify-between items-center">
          <h2 class="font-semibold text-4">{{ 'Top states' }}</h2>
          <p-dropdown
            styleClass="h-9 w-full flex ml-auto"
            [options]="topStateDropdown"
            appendTo="body"
            optionLabel="name"
            optionValue="code"
            dropdownIcon="icon-wl-arrow-drop-down"
            [(ngModel)]="selectedDataTypeTopState"
            (onChange)="onChangeDataTypeTopState()"></p-dropdown>
        </div>
        <span class="font-semibold text-7 pb-7">{{ totalTopStatesData | currency: 'USD' }}</span>

        <div class="flex-1 pt-5 pb-4">
          <p-chart
            height="256px"
            width="100%"
            type="bar"
            [data]="topStatesChartData"
            [options]="barChartOptions"></p-chart>
        </div>
      </div>
    </div>

    <div class="flex flex-col justify-between rounded-md border px-6 py-5 col-span-1">
      <div>
        <div class="flex flex-row justify-between items-center">
          <h2 class="font-semibold text-4">{{ 'Placements' }}</h2>
          <p-dropdown
            styleClass="h-9 w-full flex ml-auto"
            [options]="generalDropdown"
            appendTo="body"
            optionLabel="name"
            optionValue="code"
            dropdownIcon="icon-wl-arrow-drop-down"
            [(ngModel)]="selectedDataTypePlacements"
            (onChange)="onChangeDataTypePlacements()"></p-dropdown>
        </div>
        <span class="font-semibold text-7 pb-5">{{ totalPlacementsData | currency: 'USD' }}</span>

        <div class="flex-1 pt-5 pb-4">
          <p-chart
            height="256px"
            width="100%"
            type="bar"
            [data]="placementsChartData"
            [options]="barChartOptions"></p-chart>
        </div>
      </div>
    </div>
  </div>
}
