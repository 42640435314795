import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UNIT_NUMBER } from '@core/enum/dashboard';
import { DashboardStatisticComponent } from '@shared/components/dashboard-statistic/dashboard-statistic.component';
import { ChartData } from 'chart.js';
import { ChartModule } from 'primeng/chart';
import { BarLineChartComponent } from '../bar-line-chart/bar-line-chart.component';
import {
  ReachEngagementInsights,
  ReachEngagementStatistical
} from '@core/models/interfaces/marketing-dashboard/dashboard';
import moment from 'moment';
import { resizeArray } from '@shared/utils/resizeArray';

@Component({
  selector: 'app-reach-engagement-performance',
  standalone: true,
  imports: [DashboardStatisticComponent, ChartModule, BarLineChartComponent],
  templateUrl: './reach-engagement-performance.component.html',
  styleUrl: './reach-engagement-performance.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReachEngagementPerformanceComponent implements OnChanges {
  @Input() isLoading: boolean = false;
  @Input() reachEngagementData: ReachEngagementInsights[] = [];
  @Input() reachEngagementStatistic: ReachEngagementStatistical = {
    average_cpc: 0,
    cost_per_engagement: 0,
    cost_per_thruplay: 0,
    percentage_average_cpc: 0,
    percentage_cost_per_engagement: 0,
    percentage_cost_per_thruplay: 0
  };

  readonly UNIT_NUMBER = UNIT_NUMBER;

  statistics = [
    { id: 'AVERAGE_CPC', title: 'AVERAGE CPC', amount: 0, rate: 0, dataType: UNIT_NUMBER.CURRENCY },
    {
      id: 'COST_PER_ENGAGEMENT',
      title: 'COST PER ENGAGEMENT',
      amount: 0,
      rate: 0,
      dataType: UNIT_NUMBER.CURRENCY
    },
    { id: 'COST_PER_THRUPLAY', title: 'COST PER THRUPLAY', amount: 0, rate: 0, dataType: UNIT_NUMBER.CURRENCY }
  ];

  totalImpressions: number = 0;
  impressions: ChartData;

  totalClicks: number = 0;
  clicks: ChartData;

  totalCTR: number = 0;
  CTR: ChartData;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['reachEngagementData']?.currentValue) {
      const data = this.reachEngagementData.length > 0 ? resizeArray(this.reachEngagementData, 10) : [];

      this.mapImpressionsData(data);
      this.mapClicksData(data);
      this.mapCTRData(data);
    }

    if (changes?.['reachEngagementStatistic']?.currentValue) {
      this.mapStatisticData();
    }
  }

  getRandomNumber(multiplier: number) {
    return Math.floor(Math.random() * 41) * multiplier;
  }

  mapImpressionsData(data: ReachEngagementInsights[]) {
    this.totalImpressions = this.reachEngagementData.reduce((prev, curr) => {
      return prev + (curr.impressions || 0);
    }, 0);

    this.impressions = {
      labels: data.map(item => moment(item.date_start, 'YYYY-MM-DD').format('MMM DD')),
      datasets: [
        {
          label: 'Impressions',
          data: data.map(item => item.impressions),
          backgroundColor: data.map(() => {
            return '#3B82F6';
          }),
          borderColor: '#3B82F6',
          borderWidth: 1,
          pointRadius: 0,
          pointHoverRadius: 5,
          pointHitRadius: 10
        }
      ]
    };
  }

  mapClicksData(data: ReachEngagementInsights[]) {
    this.totalClicks = this.reachEngagementData.reduce((prev, curr) => {
      return prev + (curr.clicks_all || 0);
    }, 0);

    this.clicks = {
      labels: data.map(item => moment(item.date_start, 'YYYY-MM-DD').format('MMM DD')),
      datasets: [
        {
          label: 'Clicks',
          data: data.map(item => item.clicks_all || 0),
          backgroundColor: data.map(() => {
            return '#06B6D4';
          }),
          borderColor: '#06B6D4',
          borderWidth: 1,
          pointRadius: 0,
          pointHoverRadius: 5,
          pointHitRadius: 10
        }
      ]
    };
  }

  mapCTRData(data: ReachEngagementInsights[]) {
    this.totalCTR = this.reachEngagementData.reduce((prev, curr) => {
      return prev + (curr.ctr || 0);
    }, 0);

    this.CTR = {
      labels: data.map(item => moment(item.date_start, 'YYYY-MM-DD').format('MMM DD')),
      datasets: [
        {
          label: 'CTR',
          data: data.map(item => item.ctr || 0),
          backgroundColor: data.map(() => {
            return '#3B82F6';
          }),
          borderColor: '#3B82F6',
          borderWidth: 1,
          pointRadius: 0,
          pointHoverRadius: 5,
          pointHitRadius: 10
        }
      ]
    };
  }

  mapStatisticData() {
    const {
      average_cpc,
      cost_per_engagement,
      cost_per_thruplay,
      percentage_average_cpc,
      percentage_cost_per_engagement,
      percentage_cost_per_thruplay
    } = this.reachEngagementStatistic;
    this.statistics.forEach(statistic => {
      switch (statistic.id) {
        case 'AVERAGE_CPC': {
          statistic.amount = average_cpc;
          statistic.rate = percentage_average_cpc;
          break;
        }

        case 'COST_PER_ENGAGEMENT': {
          statistic.amount = cost_per_engagement;
          statistic.rate = percentage_cost_per_engagement;
          break;
        }

        case 'COST_PER_THRUPLAY': {
          statistic.amount = cost_per_thruplay;
          statistic.rate = percentage_cost_per_thruplay;
          break;
        }
      }
    });
  }
}
