import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseApi } from '@core/models/interfaces/common';
import {
  PerformanceInsights,
  AudienceInsights,
  ReachEngagementInsights,
  ReachEngagementStatistical
} from '@core/models/interfaces/marketing-dashboard/dashboard';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FacebookDashboardService {
  private readonly baseUrl = `${environment.API_URL}/crm/facebook-dashboards`;

  constructor(private httpClient: HttpClient) {}

  getReachEngagementStatistical(adAccountId: string, accessToken: string, since?: string, until?: string) {
    return this.httpClient.post<ResponseApi<ReachEngagementStatistical>>(
      `${this.baseUrl}/reach-engagement-statistical`,
      {
        since,
        until
      },
      {
        params: {
          adAccountId,
          accessToken
        }
      }
    );
  }

  getReachEngagementInsights(adAccountId: string, accessToken: string, since?: string, until?: string) {
    return this.httpClient.post<ResponseApi<ReachEngagementInsights[]>>(
      `${this.baseUrl}/reach-engagement-insights`,
      {
        since,
        until
      },
      {
        params: {
          adAccountId,
          accessToken
        }
      }
    );
  }

  getPerformanceInsights(adAccountId: string, accessToken: string, since?: string, until?: string) {
    return this.httpClient.post<ResponseApi<PerformanceInsights>>(
      `${this.baseUrl}/performance-insights`,
      {
        since,
        until
      },
      {
        params: {
          adAccountId,
          accessToken
        }
      }
    );
  }

  getAudienceInsights(adAccountId: string, accessToken: string, since?: string, until?: string) {
    return this.httpClient.post<ResponseApi<AudienceInsights>>(
      `${this.baseUrl}/audience-insights`,
      {
        since,
        until
      },
      {
        params: {
          adAccountId,
          accessToken
        }
      }
    );
  }

  getDashboardColumns() {
    return this.httpClient.get<ResponseApi<string[]>>(`${this.baseUrl}/dashboard-columns`);
  }

  exportReport(
    adAccountId: string,
    accessToken: string,
    name: string,
    columnSelected: string[],
    since: string,
    until: string
  ) {
    let queryParams = new HttpParams({
      fromObject: {
        adAccountId,
        accessToken,
        name
      }
    });

    queryParams = columnSelected.reduce((params, column) => params.append('columnSelected', column), queryParams);

    return this.httpClient
      .post(
        `${this.baseUrl}/export`,
        {
          since,
          until
        },
        {
          params: queryParams,
          responseType: 'blob'
        }
      )
      .pipe(
        catchError((error: any) => {
          if (error?.error instanceof Blob) {
            return new Promise((_, reject) => {
              const reader = new FileReader();
              reader.onload = () => {
                try {
                  const errorJson = JSON.parse(reader.result as string);
                  reject(errorJson); // Trả về lỗi dạng JSON
                } catch (e) {
                  reject({ message: 'Unknown error', details: error });
                }
              };
              reader.readAsText(error.error);
            });
          }
          return throwError(() => error);
        })
      );
  }
}
