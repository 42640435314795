<div class="flex flex-col justify-start gap-6">
  <div class="text-4 font-medium text-neutral-600">PERFORMANCE OVERVIEW</div>
  <div class="border rounded-md flex flex-col gap-6 p-6">
    <div class="grid grid-cols-3 gap-6">
      @for (statistic of performanceConversionLeads; track $index) {
        <div class="flex-1">
          <app-dashboard-statistic
            [title]="statistic.title"
            [amount]="statistic.amount"
            [dataType]="statistic.dataType"
            [rate]="statistic.rate"
            [isAll]="statistic.isAll"></app-dashboard-statistic>
        </div>
      }
    </div>
    @if (isLoading) {
      <p-skeleton width="100%" height="280px" />
    } @else {
      <div>
        <p-chart height="280px" type="line" [data]="dataLeads" [options]="optionsLead" />
        <app-doughnut-chart-legend [options]="leadsOptions"></app-doughnut-chart-legend>
      </div>
    }
  </div>

  <div class="border rounded-md flex flex-col gap-6 p-6">
    <div class="grid grid-cols-3 gap-6">
      @for (statistic of performanceConversionMessages; track $index) {
        <div class="flex-1">
          <app-dashboard-statistic
            [title]="statistic.title"
            [amount]="statistic.amount"
            [dataType]="statistic.dataType"
            [rate]="statistic.rate"
            [isAll]="statistic.isAll"></app-dashboard-statistic>
        </div>
      }
    </div>
    @if (isLoading) {
      <p-skeleton width="100%" height="280px" />
    } @else {
      <div>
        <p-chart height="280px" type="line" [data]="dataConversion" [options]="optionsConversion" />
        <app-doughnut-chart-legend [options]="conversionOption"></app-doughnut-chart-legend>
      </div>
    }
  </div>

  <div class="grid grid-cols-2 gap-x-6">
    <app-bar-line-chart
      [loading]="isLoading"
      title="Amount spent "
      [totalAmount]="totalAmountSpent"
      [dataType]="UNIT_NUMBER.CURRENCY"
      [data]="dataAmountSpent"></app-bar-line-chart>

    <!-- Doughnut -->
    <div class="border rounded-md flex flex-col gap-6 p-6">
      <div class="flex flex-col gap-1">
        <span class="text-4 font-semibold">Total revenue</span>
        <span class="text-7 font-semibold"> {{ totalRevenue | currency }} </span>
      </div>
      <div class="w-full flex flex-col items-center h-full justify-between">
        <p-chart height="240px" type="doughnut" [data]="conversionRevenue" [options]="doughnutChartOptions" />
        <app-doughnut-chart-legend class="w-full" [options]="doughnutOption"></app-doughnut-chart-legend>
      </div>
    </div>
  </div>
</div>
